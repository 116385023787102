import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import RelativeWrapper from '../../components/layout/RelativeWrapper';

import { ViewportBreakpoints } from '../../components/cssConstants';

import FeatureListBreaker from '../../components/cta/FeatureListBreaker';
import GetStarted from '../../components/cta/GetStarted';
import FeatureRow from '../../components/section/FeatureRow';
import ClientLogoBar, { ClientLogos } from '../../components/section/ClientLogoBar';
import CaseStudyCarousel from '../../components/section/CaseStudyCarousel';

import CheckmarkTextList from '../../components/frills/CheckmarkTextList';

import HeroH1 from '../../components/hero/HeroH1';
import HeroH2 from '../../components/hero/HeroH2';

import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

const DarkWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand180};
  background-repeat: no-repeat;

  background-position: center bottom;
  background-size: 100% auto;
  background-image: url(${props => props.backgroundMobile});

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    background-position: left calc(40% - 550px) top;
    background-size: auto 100%;
    background-image: url(${props => props.backgroundDesktop});
    transform: translate3d(0, -80px, 0);
  }
`;

const Hero = styled.div`
  padding: 80px 0 470px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 210px 0 170px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
    justify-content: flex-end;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 40%;
  }
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const Subtitle = styled(Text)`
  margin: 20px 0 40px 0;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme: { colors } }) => colors.grey};
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
};

const Page = ({ data }) => (
  <Body header={header}>
    <SEO
      title="Hospitality staff rota software - Rotaready"
      description="Designed with cafes, bars, restaurants and hotels in mind, our rota scheduling software can save you hours of admin time and optimise your staff wage costs."
      url="hospitality"
    />
    <DarkWrapper
      backgroundDesktop={data.heroBackgroundDesktop.childImageSharp.fixed.src}
      backgroundMobile={data.heroBackgroundMobile.childImageSharp.fixed.src}
    >
      <ResponsiveContainer>
        <Hero>
          <HeroInnerContainer>
            <HeroBody>
              <HeroH1 color="brand" text="Hospitality" />
              <HeroH2 color="white" text="Hassle free staff scheduling for hospitality" />

              <H4 uistyle="white">
                Rotaready was born in the world of pubs, bars, restaurants and
                hotels, where it's crucial to get your wage spend right. And it all
                lies in a great staff rota.
              </H4>

              <CtaButtons>
                <Link to="/demo">
                  <Button uistyle="primary" text="Request demo" size="lg" />
                </Link>
                <Link to="/contact">
                  <Button uistyle="white" text="Speak to the team" size="lg" ghost borderless />
                </Link>
              </CtaButtons>
            </HeroBody>
          </HeroInnerContainer>
        </Hero>
      </ResponsiveContainer>
    </DarkWrapper>

    <ClientLogoBar
      logos={[
        ClientLogos.HICKORYS_SMOKEHOUSE,
        ClientLogos.GUSTO,
        ClientLogos.PIZZA_PILGRIMS,
        ClientLogos.BREWDOG,
        ClientLogos.WARNER_LEISURE_HOTELS,
        ClientLogos.MOLLIES,
        ClientLogos.DISHOOM,
        ClientLogos.NQ64,
        ClientLogos.CREAMS,
        ClientLogos.HUBBOX,
        ClientLogos.TOPGOLF,
      ]}
    />

    <FeatureRow
      mirrorArrange
      title="Loved by the most forward thinking brands in hospitality"
      illustrationImage={data.venuePickerIllustration.childImageSharp.fluid}
      illustrationAltText="Switching between venues within Rotaready"
    >
      <Subtitle text="Whether you look after 1 cafe or 100 restaurants, our cloud based rota software gives you full visibility and control over your rotas and your people." />

      <CheckmarkTextList
        items={[
          'Easily schedule staff rotas that are cost efficient and aligned to demand',
          'Control financial performance with expected labour costs and powerful budgeting',
          'Monitor staff attendance and timeliness across your entire estate',
          'Produce perfect timesheets for payroll, including tronc and overtime',
        ]}
      />
    </FeatureRow>

    <FeatureRow
      backgroundColor="brand10"
      title="Save time on staff scheduling and optimise your wage spend"
      illustrationImage={data.automatedSchedulingIllustration.childImageSharp.fluid}
      illustrationAltText="Automatically allocating shifts to restaurant staff within Rotaready"
    >
      <Subtitle text="The ability to automatically build rotas informed by employee availability, skills, budgets and past schedules means you can get back to the important things, like serving tasty grub and delicious cocktails." />

      <LinkWithArrow
        direction="right"
        to="/rota-scheduling"
        text="Find out more about scheduling"
        render={props => <Link {...props} />}
      />
    </FeatureRow>

    <FeatureRow
      mirrorArrange
      title="Power to the people"
      illustrationImage={data.mobileScheduleIllustration.childImageSharp.fluid}
      illustrationAltText="Hospitality employee view of upcoming shifts within Rotaready"
    >
      <Subtitle text="The Rotaready mobile app comes as standard, and it's designed for everyone from chefs and waiters to baristas and hosts. They can check their upcoming shifts, clock-in, swap shifts and request time off at the tap of a button." />

      <CheckmarkTextList
        items={[
          'Real-time shift update notifications & rota changes',
          'Book holiday, view this year\'s allowance or accrued hours',
          'Swap shifts amongst colleagues',
          'Clock-in and out from within the app, using secure GPS',
          'Pick up extra hours with Shift Broadcasts',
          'Increase staff engagement and drive retention',
        ]}
      />
    </FeatureRow>

    <FeatureRow
      backgroundColor="brand10"
      title="Take the guesswork out of scheduling and forecasting"
      illustrationImage={data.demandVsBookingsIllustration.childImageSharp.fluid}
      illustrationAltText="Viewing number of staff vs number of bookings within Rotaready"
    >
      <Subtitle text="Historical sales, upcoming events, reservations, the weather, and Rotaready's intelligent forecasts, all in one place. So you can build rotas that find the perfect balance between expected demand and the people needed to serve it." />

      <CheckmarkTextList
        columns={1}
        items={[
          'Automatic sales predictions, powered by Rotaready\'s artificial intelligence',
          'Bring everything together in one platform with our included integrations, from EPOS to reservations',
        ]}
      />

      <LinkWithArrow
        direction="right"
        to="/demand-forecasting"
        text="Learn about demand forecasting"
        render={props => <Link {...props} />}
      />
    </FeatureRow>

    <FeatureRow
      mirrorArrange
      title="Compliance never looked so easy"
      illustrationImage={data.rotaValidationIllustration.childImageSharp.fluid}
      illustrationAltText="Staff scheduling validation issues highlighted within Rotaready"
    >
      <Subtitle text="Running a restaurant or hotel is stressful enough, let alone making sure you've given people the right hours and the legal amount of time off. Thankfully Rotaready handles it all." />

      <CheckmarkTextList
        items={[
          'Secure staff document storage with expiry-date alerts',
          'Live rota validation that checks staff contractual hours, skills and more',
          'Automatic compliance with all the rules of the Working Time regulations',
          'Fully compliant with GDPR and other Data Protection legislation',
        ]}
      />
    </FeatureRow>

    <CaseStudyCarousel />

    <RelativeWrapper backgroundColor="white">
      <FeatureListBreaker displayCopy rows={2} />
    </RelativeWrapper>

    <GetStarted />
  </Body>
);

export default Page;

export const query = graphql`
  query {
    heroBackgroundDesktop: file(relativePath: { eq: "misc/hero-hospitality-desktop.png" }) {
      childImageSharp {
        fixed(height: 1580, width: 2438, quality: 30) {
          src
        }
      }
    }

    heroBackgroundMobile: file(relativePath: { eq: "misc/hero-hospitality-mobile.png" }) {
      childImageSharp {
        fixed(height: 894, width: 750, quality: 30) {
          src
        }
      }
    }

    venuePickerIllustration: file(relativePath: { eq: "illustrations/venue-picker.png" }) {
      childImageSharp {
        fluid(maxWidth: 442, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    automatedSchedulingIllustration: file(relativePath: { eq: "illustrations/automated-scheduling.png" }) {
      childImageSharp {
        fluid(maxWidth: 536, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    mobileScheduleIllustration: file(relativePath: { eq: "illustrations/mobile-schedule.png" }) {
      childImageSharp {
        fluid(maxWidth: 489, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    demandVsBookingsIllustration: file(relativePath: { eq: "illustrations/demand-vs-bookings.png" }) {
      childImageSharp {
        fluid(maxWidth: 519, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    rotaValidationIllustration: file(relativePath: { eq: "illustrations/rota-validation.png" }) {
      childImageSharp {
        fluid(maxWidth: 465, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`;
